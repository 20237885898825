import { Tabs } from "@mantine/core";

export const tabsComponentSettings = Tabs.extend({
  defaultProps: {
    color: "bay.8",
  },
  styles: (theme, { variant }) => {
    if (variant === "pills") return {};
    return {
      tab: {
        marginRight: 16,
        paddingLeft: 0,
        paddingRight: 0,
        color: theme.colors.gray[5],

        // When in "active" state a border will show around the tab button
        // to indicate focus / pressed. If border-radius is unset, we'll see
        // rounded corners at the top and squared corners at the bottom,
        // which looks *real* weird, so we disable.
        borderRadius: 0,
        "&[data-active]": {
          color: theme.colors.bay[7],
        },
      },
      tabLabel: {
        fontSize: 16,
        lineHeight: "26px",
        "&[data-active]": {
          color: theme.colors.bay[7],
        },
      },
      list: {
        "&:before": {
          borderBottomColor: "transparent",
        },
      },
      tabSection: {
        color: theme.colors.bay[7],
      },
    };
  },
});
