import { Badge } from "@mantine/core";

import classNames from "./Badge.module.css";

export const badgeComponentSettings = Badge.extend({
  defaultProps: {
    variant: "light",
  },
  classNames,
});
